import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

export const BlogList = () => {
    const data = useStaticQuery(graphql`
        query BlogListQuery {
            allPrismicBlogPost {
                edges {
                node {
                    dataRaw
                }
                }
            }
        }
    `)
    const posts = data.allPrismicBlogPost.edges

    return (
        <ul>
            {posts && posts.map((post, i) => {
                const postData = post.node.dataRaw
                return <li key={i}><Link to={`/blog/${postData.slug}`}>{postData.title}</Link></li>
            })}
        </ul>
    )
}