import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import '../styles/blog-post.scss'
import { CoverImage } from "../components/coverImage"
import { BlogList } from "../components/blogList"
import { RichTextPrismic } from "../components/richTextPrismic"
import { Layout } from "../layout/layout"


export default function BlogPost({ pageContext }) {
    const { title, lede, cover_image, body } = pageContext.data
    return (
        <Layout>
            <Helmet>
            <title>{title}</title>
            <meta name="description" content={lede} />
            </Helmet>
            <div className="t-blog-post">
                <div className="t-blog-post__container">
                    <p><Link to="/blog">Back to blog</Link></p>
                    <CoverImage image={cover_image} />
                    <div className="grid t-blog-post__content">
                        <div className="col-8_sm-12">
                            <h1 className="t-blog-post__title">{title}</h1>
                            <p className="t-blog-post__lede">{lede}</p>
                            <RichTextPrismic body={body} />
                            <div className="c-closing-words">
                                <p>Thank you for reading my blog post. If you have any questions, feel free to contact me on <a href="https://instagram.com/kursori" target="blank" rel="noopener">Instagram</a>.</p>
                            </div>
                        </div>
                        <div className="col-4_sm-12">
                            All posts:
                            <BlogList />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}