import React from 'react'
import { RichText } from 'prismic-reactjs'
import '../styles/rich-text.scss'

export const RichTextPrismic = ({ body }) => {
    return (
        <div className="c-rich-text">
            <RichText render={body} />
        </div>
    )
}